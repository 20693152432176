import { Html5QrcodeScanner, Html5QrcodeScanType } from 'html5-qrcode';
import './App.css';
import React from 'react';
import { Dropdown } from 'react-bootstrap';

function Header({title = ""}) {
  return (<div className="header">
    <img src="/logo_transparent.png" className='logo' alt="TechnikAG"/>
    <h1>{title ?? ""}</h1>
    <Dropdown>
      <Dropdown.Toggle >
        <span>☰</span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href="/overview">Geräte-Übersicht</Dropdown.Item>
        <Dropdown.Item href="/">Scannen</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  </div>);
}

//function getBaseUrl() {
//  return "http://localhost:2468";
//}

function getBaseUrl() {
  return "";
}

function thumbnail(baseUrl, data){
  if(data.image_id === null || data.image_id === undefined) return <img src="/default_icon.png" alt='object icon'/>
  else return <img src={`${baseUrl}/api/image/${data.image_id}`} alt="object icon"/>
}

class ObjectDescription extends React.Component {

  data = {};
  baseUrl = "";

  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
    this.data = this.props.data;
    this.baseUrl = this.props.baseUrl;
  }

  render() {
    return (<div className="objectTypePanel">
      <div className="objectTypeIcon">
        {thumbnail(this.baseUrl, this.data)}
      </div>
      <div className="objectTypeDetails">
        <h3 className='object_type_name'>{this.data.display_name}</h3>
        <span className='object_type_description'>{this.data.description}</span>
      </div>
      
    </div>);
  }
}

class OverviewPage extends React.Component{

  render(){
    if(this.isLoading) return <div className='App'><Header title='Wird geladen...'/></div>

    return <div className='App'>
      <Header title="Inventarsystem Übersicht"/>
      {
        this.data.items.map(this.listItem)
      }
    </div>;

  }

  listItem(data, index){
    const clsname = (index % 2 === 0) ? "HistoryItemEven" : "HistoryItemOdd";
    const objtype = this.data.descriptions.find(a => a.id === data.item.type);
    return <a href={`/history?id=${encodeURIComponent(data.item.id)}`} target='_blank' rel='noreferrer' class={clsname}>
        <h3 className='item_code'>Code: {data.item.code}</h3>
        <div className="objectTypeIconSmall">
          {thumbnail(this.baseUrl, objtype)}
        </div>
        <span className='lblObjectType'>{objtype.display_name}</span>
        {
            (data.state.type === "CHECKIN") ? <div className='checkinItem'>Eingecheckt</div> : <div className='checkoutItem'>Ausgecheckt</div>
        }
        <div className='overviewActorPanel'>
          <h3>{data.state.actor}</h3>
          <div>{data.state.actor_category}</div>
        </div>
    </a>;
  }
  
  isLoading = true;
  data = {};

  constructor(){
    super();
    this.render = this.render.bind(this);
    this.listItem = this.listItem.bind(this);
    this.Load();
  }

  async Load(){
    try{
      const response = await fetch(`${this.baseUrl}/api/state`)
      if(response.status !== 200 ){

      } else {
        let item = await response.json();
        console.log(item);
        this.data = item;
        this.isLoading = false;
        this.setState({});

      }
      
    } catch(ex){
      console.log(ex);
      this.setState({});
      return;
    }
  }


  baseUrl = getBaseUrl();

}



class HistoryPage extends React.Component {

  render(){

    if(this.isLoading) return <div className='App'><Header title='Wird geladen...'/></div>

    return <div className='App'>
      <Header title={this.data.type.display_name}/>
      
      <div className="objectTypePanel">
        <div className="objectTypeIcon" >
          {thumbnail(this.baseUrl, this.data.type)}
          <div class="image_edit_overlay" onClick={e => this.SetImage()}>
            <span class="icon" >🖊️</span>
          </div>
        </div>
        <div className="objectTypeDetails">
          <h3 className='object_type_description'>{this.data.type.description}</h3>
          <span>Code: {this.data.item.code}</span>
        </div>
      </div>

      <h2>Historie</h2>

      {this.data.history.map((item, index) => 
      <div className={(index % 2 === 0) ? "HistoryItemEven" : "HistoryItemOdd"}>
        {
            (item.type === "CHECKIN") ? <div className='checkinItem'>Eingecheckt</div> : <div className='checkoutItem'>Ausgecheckt</div>
        }
        <div className='historyActorPanel'>
          <h3>{item.actor}</h3>
          <div>{item.actor_category}</div>
        </div>
        <span className='HistoryTimestamp'>{new Date(item.timestamp).toLocaleString()}</span>
      </div>)}

    </div>;
  }

  async SetImage(){
    var input = document.createElement('input');
    input.type = 'file';
    input.accept = ".png,.jpg,.jpeg,.bmp";

    input.onchange = async e => { 
      var file = e.target.files[0]; 
      console.log(file);
      
      await fetch(`${this.baseUrl}/api/itemtype/${encodeURIComponent(this.data.type.id)}/image`, { method: "POST", body: file});
      window.location.reload();
    }

    input.click();
  }

  isLoading = true;
  id = "";
  data = {};

  constructor(){
    super();
    this.render = this.render.bind(this);
    this.SetImage = this.SetImage.bind(this);
    const urlParams = new URLSearchParams(window.location.search);
    this.id = urlParams.get('id') ?? "";
    this.Load();
  }

  async Load(){
    try{
      const response = await fetch(`${this.baseUrl}/api/history/${encodeURIComponent(this.id)}`)
      if(response.status !== 200 ){

      } else {
        let item = await response.json();
        console.log(item);
        this.data = item;
        this.isLoading = false;
        this.setState({});
        document.title = this.data.item.code + ": " + this.data.type.display_name;
      }
      
    } catch(ex){
      console.log(ex);
      this.setState({});
      return;
    }
  }


  baseUrl = getBaseUrl();

}

class ScanPage extends React.Component {

  constructor(){
    super();
    this.render = this.render.bind(this);
    this.onScanSuccess = this.onScanSuccess.bind(this);
    this.onCodeEntered = this.onCodeEntered.bind(this);
    this.fetchItemTypes = this.fetchItemTypes.bind(this);
    this.CreateNewItem = this.CreateNewItem.bind(this);
    this.ShowActorPanelIfNecessary = this.ShowActorPanelIfNecessary.bind(this);

    this.myActor = localStorage.getItem("username") ?? "";
    this.myActorCategory = localStorage.getItem("category") ?? "";
  }
  
  baseUrl = getBaseUrl();

  scanner = {};
  manualCodeInput = "";
  actionState = "SCAN";
  errorMessage = "";

  currentItemCode = "";

  createNewItemType = false;
  selectedItemType = 0;
  itemTypes = [];
  newItem_newName = "";
  newItem_newDescription = "";

  myActor = "";
  myActorCategory = "";

  loadedResponse = {};

  render() {
      switch(this.actionState) {
        case "SCAN":
          return (<div className="App">
            <Header title="Scannen"/>
              <div id="reader"></div>
              <h3>- oder -</h3>
              <h3>Code eingeben</h3>
              <div>
                <input defaultValue={this.manualCodeInput} onChange={e => this.manualCodeInput = e.target.value} className='codefield'/>
                <button onClick={e=> this.onCodeEntered(this.manualCodeInput)}>weiter</button>
              </div>
              {this.ShowActorPanelIfNecessary()}
          </div>
        );
        case "LOAD":
          return (<div className="App">
            <Header title="Lade..."/>
            <h3>Wird geladen...</h3>
          </div>);
        case "ERROR":
            return (<div className="App">
            <Header title="Fehler"/>
              <h3>{"" + this.errorMessage}</h3>
            </div>);
        case "SUCCESS_CREATED":
            return (<div className="App">
            <Header title="Objekt erfolgreich angelegt."/>
              <button onClick={e => window.location.reload() }>Zurück</button>
              <div>Das Objekt gilt jetzt im System als "Eingecheckt".</div>
            </div>);
        case "SUCCESS_CHECKOUT":
            return (<div className="App">
            <Header title="Objekt erfolgreich ausgecheckt."/>
              <button onClick={e => window.location.reload() }>Zurück</button>
            </div>);
        case "SUCCESS_CHECKIN":
            return (<div className="App">
            <Header title="Objekt erfolgreich eingecheckt."/>
              <button onClick={e => window.location.reload() }>Zurück</button>
            </div>);
        case "SUCCESS_FOUND":
            return (<div className="App">
              <Header/>
                <ObjectDescription baseUrl={this.baseUrl} data={this.loadedResponse.type}/>
                <b>code: {this.loadedResponse.item.code}</b>
                { (this.loadedResponse.state.type === "CHECKIN") ? <button className='largeAction' onClick={e => this.CheckoutCurrentObject()}>Auschecken</button> : <button className='largeAction'  onClick={e => this.CheckinCurrentObject()}>Einchecken</button> }
                { (this.loadedResponse.state.type === "CHECKIN") ? <button className='smallAction' onClick={e => this.CheckinCurrentObject()}>Einchecken</button> : <button className='smallAction'  onClick={e => this.CheckoutCurrentObject()}>Auschecken</button> }
                <button onClick={e => window.location.reload() }>Zurück</button>
                </div>);
        case "NEWITEM":
          return (<div className="App">
            <Header/>
              <h2>Der Code war im System nicht vorhanden</h2>
              <h3>Neu anlegen</h3>
              <div>
                <label>Objektart</label>
                <select onChange={e => {this.createNewItemType = e.target.selectedIndex === 0; this.selectedItemType = e.target.selectedIndex - 1; this.setState({})} }>
                <option>-- Neue Objektart anlegen --</option>
                  { this.itemTypes.map(a => <option>{a.display_name}</option>) }
                </select>
              </div>

              {(this.createNewItemType) ? 
                <div>
                  <div>
                    <label>Name</label>
                    <input defaultValue={this.newItem_newName} onChange={e => this.newItem_newName = e.target.value}/>
                  </div>
                  <div>
                    <label>Beschreibung</label>
                    <input defaultValue={this.newItem_newDescription} onChange={e => this.newItem_newDescription = e.target.value}/>
                  </div>

                </div> : null
              }
                  <button onClick={e => this.CreateNewItem()}>Neues Objekt Speichern</button>
          </div>
         );
        default:
          return (<div className="App">
            <Header/>
            <h3>Unbekannter Status {this.actionState}</h3>
          </div>);
      }
      
  }

  actorCategories = ["Technik-AG", "Schulleitung", "Hausmeister / Stadt", "Musikfachschaft"];


  async CheckoutCurrentObject(){
    try{
      const response = await fetch(`${this.baseUrl}/api/checkout/${encodeURIComponent(this.loadedResponse.item.id)}?actor=${encodeURIComponent(this.myActor)}&category=${encodeURIComponent(this.myActorCategory)}`, { method: 'POST'} )
      let item = await response.json();
      console.log(item);

      this.actionState = "SUCCESS_CHECKOUT";
      this.setState({});

    } catch(ex){
      console.log(ex);
      this.errorMessage = ex;
      this.actionState = "ERROR";
      this.setState({});
      return;
    }
  }

  async CheckinCurrentObject(){
    try{
      const response = await fetch(`${this.baseUrl}/api/checkin/${encodeURIComponent(this.loadedResponse.item.id)}?actor=${encodeURIComponent(this.myActor)}&category=${encodeURIComponent(this.myActorCategory)}`, { method: 'POST'} )
      let item = await response.json();
      console.log(item);

      this.actionState = "SUCCESS_CHECKIN";
      this.setState({});

    } catch(ex){
      console.log(ex);
      this.errorMessage = ex;
      this.actionState = "ERROR";
      this.setState({});
      return;
    }
  }


  ShowActorPanelIfNecessary(){
    if(this.myActor.trim() !== "") return null;
    this.myActorCategory = this.actorCategories[0];
    return (<div className='overlay'>
      <div class="agent">
      <div className="agent_col1">
        <h1>Wer sind Sie?</h1>
        <div>
          <label>Benutzername:</label>
          <input defaultValue={this.myActor} onChange={e => this.myActor = e.target.value}/>
        </div>
        <div>
          <label>Abteilung:</label>
          <select onChange={e => this.myActorCategory = this.actorCategories[e.target.selectedIndex]}>
            {this.actorCategories.map(a => <option>{a}</option>)}
          </select>
        </div>
        <div>
          <span>Diese option wird im Browser gespeichert.</span>
        </div>
        <button onClick={e => {
            localStorage.setItem("username", this.myActor);
            localStorage.setItem("category", this.myActorCategory);
            this.setState({}); }}
            >Speichern</button>
      </div>
      <div className="agent_col2">
        <img  src='/agent.png' alt='agent'/>
      </div>
      </div>
    </div>);
  }

  onScanSuccess(decodedText, decodedResult) {
    // Handle on success condition with the decoded text or result.
    console.log(`Scan result: ${decodedResult.decodedText}`);
    this.onCodeEntered(decodedResult.decodedText);
  }

  async fetchItemTypes(){
    const response = await fetch(`${this.baseUrl}/api/state`);
    const data = await response.json();
    this.itemTypes = data.descriptions;
  }

  async CreateNewItem(){
    
    var itemType = {};
    if(this.createNewItemType) {
      try{
        const response = await fetch(`${this.baseUrl}/api/itemtype?displayname=${encodeURIComponent(this.newItem_newName)}&description=${encodeURIComponent(this.newItem_newDescription)}`, { method: 'PUT'} );
        itemType = await response.json();
      }catch(ex){
        console.log(ex);
        this.errorMessage = ex;
        this.actionState = "ERROR";
        this.setState({});
        return;
      }
    } else {
      itemType = this.itemTypes[this.selectedItemType];
    }

    console.log(itemType);

    try{
      const response = await fetch(`${this.baseUrl}/api/item?itemtype=${encodeURIComponent(itemType.id)}&code=${encodeURIComponent(this.currentItemCode)}`, { method: 'PUT'} );
      let item = await response.json();
      console.log(item);

      const response2 = await fetch(`${this.baseUrl}/api/checkin/${encodeURIComponent(item.id)}?actor=${encodeURIComponent(this.myActor)}&category=${encodeURIComponent(this.myActorCategory)}`, { method: 'POST'} )
      let item2 = await response2.json();
      console.log(item2);

      this.actionState = "SUCCESS_CREATED";
      this.setState({});

    } catch(ex){
      console.log(ex);
      this.errorMessage = ex;
      this.actionState = "ERROR";
      this.setState({});
      return;
    }
    
  }

  async onCodeEntered(code) {
    if(this.actionState !== "SCAN") return;

    if(this.myActor.trim() === "") return;

    console.log(`Code entered: ${code}`);
    code = code.trim();
    if(code === "") return;
    this.actionState = "LOAD";
    this.manualCodeInput = "";
    this.setState({});

    try{
      let response = await fetch(`${this.baseUrl}/api/itembycode/${encodeURIComponent(code)}`);
      if(response.status === 404) {
        this.currentItemCode = code;
        await this.fetchItemTypes();
        this.actionState = "NEWITEM";
        this.createNewItemType = true;
        this.setState({});
      }  else if (response.status === 200){
        this.currentItemCode = code;
        let data = await response.json();
        console.log(data);
        this.loadedResponse = data;
        this.actionState = "SUCCESS_FOUND";
        this.setState({});
      } else {
        this.errorMessage = `Status ${response.status}`;
        this.actionState = "ERROR";
        this.setState({});
      }
      
    } catch(ex){
      console.log(ex);
      this.errorMessage = "Error";
      this.actionState = "ERROR";
      this.setState({});
    }
  }

  mounted = false;

  componentDidMount(){
    if(this.mounted) return;
    this.mounted = true;
    this.scanner = new Html5QrcodeScanner("reader", {fps: 25, qrbox:500, supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA], rememberLastUsedCamera: true });
    this.scanner.render(this.onScanSuccess);
  }

}



export{ ScanPage, HistoryPage, OverviewPage}

